<template>
  <div class="coupon-scrollable" data-t="coupon-scrollable" :class="classes">
    <div ref="el" class="scroll-container" data-t="scroll-container-prtn">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const el = ref<HTMLDivElement>()
const { arrivedState, measure } = useScroll(el)

const { height } = useElementSize(el)

watch(height, () => measure())

const classes = computed(() => ({
  'arrived-top': arrivedState.top,
  'arrived-bottom': arrivedState.bottom,
}))
</script>

<style scoped>
.coupon-scrollable {
  position: relative;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition:
    border-bottom-color 0.2s,
    border-top-color 0.2s;

  &::after,
  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;

    height: 32px;

    opacity: 0;
  }

  &::before {
    top: 0;
    background: linear-gradient(#111118 0%, rgb(17 17 24 / 0%) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(0deg, #111118 0%, rgb(17 17 24 / 0%) 100%);
  }

  &:not(.arrived-top) {
    border-top-color: var(--border-secondary);

    &::before {
      opacity: 1;
    }
  }

  &:not(.arrived-bottom) {
    border-bottom-color: var(--border-secondary);

    &::after {
      opacity: 1;
    }
  }
}

.scroll-container {
  overflow-y: auto;
  flex-grow: 1;

  height: 100%;
  margin-right: calc(var(--spacing-400) * -1);
  padding-right: var(--spacing-400);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
